import { AfterViewInit, Directive, ElementRef, EventEmitter, Input, OnDestroy, Output, inject } from "@angular/core";

@Directive({
  selector: "[fdIsVisible]",
  standalone: true,
})
export class IsVisibleDirective implements AfterViewInit, OnDestroy {
  @Output() elementVisible = new EventEmitter<boolean>();
  @Input() isTargetElement: boolean;
  @Input() threshold: number = 1;

  private readonly element = inject(ElementRef);
  private observer: IntersectionObserver;

  ngAfterViewInit() {
    if (this.isTargetElement) {
      this.observer = new IntersectionObserver(this.intersectionCallback.bind(this), {
        root: null,
        rootMargin: "0px",
        threshold: [0, 0.5, 1],
      });
      this.observer.observe(this.element.nativeElement);
    }
  }

  ngOnDestroy(): void {
    this.observer?.disconnect();
  }

  intersectionCallback(entries: IntersectionObserverEntry[], _observer: IntersectionObserver) {
    if (this.isTargetElement) {
      entries.forEach(entry => {
        this.elementVisible.emit(entry.intersectionRatio >= this.threshold);
      });
    }
  }
}
