import { AppEnvironment, IEnvironment } from "@fd/core";

export interface IAppEnvironment extends IEnvironment {
  isFdAngularSite: boolean;
}

export const defaultEnvironment: IAppEnvironment = {
  isFdAngularSite: document.title === "FD Angular",
  configuration: AppEnvironment.Development,
  testingUrl: "https://samples.test.scottgroupstudio.com/",
  productionUrl: "https://samples.scottgroupstudio.com/",
  localServerUrl: require("../../../Properties/launchSettings.json").profiles.Development.applicationUrl,
  passwordRequirements: {
    minLength: 6,
    digit: true,
    upperCase: true,
    lowerCase: true,
  },
  passwordRequirementMessages: [
    "Must be at least 6 characters in length.",
    "Must contain an uppercase letter.",
    "Must contain a lowercase letter.",
    "Must contain a number.",
  ],
  onlineStatusNotifications: {
    enabled: true, // When true, notifications will be shown when network connectivity is lost or restored
    connectionRestored: "You are now back online!",
    connectionLost: "You are offline.",
  },
  matPaginatorDefaultOptions: {
    formFieldAppearance: "fill",
    hidePageSize: false,
    pageSize: 25,
    pageSizeOptions: [5, 25, 100],
    showFirstLastButtons: true,
  },
  matFormFieldDefaultOptions: {
    appearance: "fill",
  },
  theme: {
    // set to true to enable dark mode. In styles/_variables.scss $dark-mode must be true as well
    supportsDarkMode: true,
  },
};
